<template>
  <campaign-tracking-form
    :model="overview.model"
    :is-loading="isLoading"
    @submit="handleSubmit"
  />
</template>

<script>
// Import child components
const CampaignTrackingForm = () => import(/* webpackChunkName: "campaign-tracking-form" */ "@/components/crm/campaign-tracking/Form.vue")

// Export the SFC
export default {
  // Name of the SFC
  name: "CampaignTrackingEdit",

  // Register children components
  components: {
    CampaignTrackingForm
  },

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether we're making a network request or not
    isLoading: false
  }),

  // Define local method functions
  methods: {
    /**
     * Handle the submit event from child form view
     *
     * @returns {void}
     */
    async handleSubmit(formData) {
      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Make the network request
      try {
        // Append the _method to the form data
        formData.append("_method", "PUT")

        // Get the response
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}`,
          method: "POST",
          data: formData
        })

        // Show a success toast
        this.$store.dispatch("toasts/add", { text: "Campaign updated!" })

        // Update the model data in the store
        this.$store.dispatch("campaignTracking/fetchDetails", this.overview.model.uuid)
      } catch (error) {
        // Log the error
        logger({ type: "CampaignTracking/Update Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  }
}
</script>
